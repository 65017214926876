@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import "./variables.scss";
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}
body {
    background-color: #bfbfbf14;
}

.App {
    display: grid;
    grid-template-columns: 20% 80%;
    grid-auto-rows: minmax(min-content, max-content);
    align-items: start;
    .main-nav {
        grid-column: 1/-1;
    }
    .sidebar {
        width: 100%;
        height: 100vh;
        position: sticky;
        top: 0;
    }
    header.header {
        background: #fff;
        grid-column: 2/-1;
        border-bottom: 1px solid #f0f0f0;
        grid-auto-rows: minmax(min-content, max-content);
        align-self: start;
        justify-self: start;
        height: fit-content;
        padding: 1rem;
        width: 100%;
        .title {
            font-weight: 600;
            margin: 0;
        }
    }
    main.main {
        // border: 1px solid black;
        grid-column: 2/-1;
        margin: 1rem;
        &.with-bg {
            background: #fff;
            border-radius: 5px;
        }
    }
    footer.footer {
        background: #fff;
        grid-column: 2/-1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-shadow: 0 0 15px rgba($color: #000000, $alpha: 0.2);
        padding: 2rem;
        .copy-text {
            font-family: 'Laila', sans-serif;
            font-weight: 500;
            text-align: center;
            color: rgb(82, 82, 82);
            .bold {
                color: rgb(38, 38, 38);
            }
        }
    }
}

.sidebar-fixed-bottom-container {
    position: fixed;
    bottom: 1rem;
    left: 0;
    width: 20%;
    a, button{
        padding-left: 24px;
        cursor: pointer;
        display: block;
        width: 100%;
        text-align: left;
        font-size: 0.85rem;
        transition: color 0.3s, background 0.3s, padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        .text{
            padding-left: 5px;
        }
        &:hover {
            color: #1890ff;
        }
    }
    button{
        border: none;
        background: transparent;
    }
}

.button {
    padding: 0.3rem 0.5rem;
    cursor: pointer;
    border-radius: 5px;
    transition: 0.4s all cubic-bezier(0.215, 0.610, 0.355, 1);
    text-decoration: none;
    &.button-primary {
        background-color: rgb(53, 109, 155);
        color: #fff;
        &:hover {
            background-color: rgb(69, 131, 181);
        }
    }
    &.button-nav-profile {
        width: 100%;
    }
    &.button-regular {
        border: 1px solid #1890ff;
        background-color: #fff;
        color: #1890ff;
        &:hover {
            background: #1890ff;
            color: white;
        }
        &.disable-effect {
            &:hover:disabled {
                background-color: #fff;
                color: #1890ff;
            }
        }
    }
    &.button-danger {
        border: none;
        background: #E74C3C;
        color: white;
        &:hover {
            background: #CB4335;
        }
    }
    &.button-warning {
        border: none;
        background: #F39C12;
        color: white;
        &:hover {
            background: #D68910;
        }
    }
    &.button-simple {
        border: none;
        background-color: transparent;
        padding: 0.3rem;
        position: absolute;
        right: 0.3rem;
        bottom: 0rem;

    }
}
.main-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.3rem 1rem;
    box-shadow: 0 0 5px rgba($color: #000000, $alpha: 0.3);
    .logo-container {
        display: grid;
        justify-content: start;
        grid-template-columns: auto auto;
        .logo {
            grid-row: 1/3;
            grid-column: 1/2;
            width: 4rem;
            margin-right: 1rem;
            img {
                width: 100%;
            }
        }
        .title {
            grid-row: 1/2;
            font-size: 1.3rem;
            font-weight: 700;
            margin: 0;
            align-self: flex-end;
            line-height: 1;
            margin-bottom: 0.3rem;
        }
        .sub-title {
            grid-row: 2/3;
            font-size: 1rem;
            margin: 0;
            align-self: flex-start;
            line-height: 1;
        }
    }
    .links-container {
        .link {
            cursor: pointer;
            &.profile {
                display: flex;
                gap: 0.5rem;
                align-items: center;
            }
        }
    }
}

main.model-view {
    .main-container {
        background: #fff;
        padding: 1rem;
        margin-top: 1rem;
        border-radius: 5px;
        .actions-container {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 1rem;
            margin-bottom: 1rem;
        }
        .table-container {

        }
    }
}
.capitalize {
    text-transform: capitalize;
}
.name-container {
    .name {
        position: relative;
        text-align: left;
    }
    .org-name {
        color: #f06548;
    }
    .email {
        font-size: 0.7rem;
        text-align: right;
    }
}
.table-buttons-container {
    display: flex;
    justify-content: flex-start;
    gap: 1rem
}
.place-status-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .text {
        padding: 0.3rem 0.5rem;
        padding-left: 1rem;
        color: rgb(53, 109, 155);
        transition: 0.4s all cubic-bezier(0.215, 0.61, 0.355, 1);
        text-decoration: none;
    }
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba($color: #000000, $alpha: 0.3);
    z-index: $add-model-overlay-z-index;
}
.pop-model {
    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 30%;
    transform: translate(-50%, -50%);
    z-index: $add-model-z-index;
    min-height: 50vh;
    background-color: #fff;
    border-radius: 5px;
    padding: 1rem;
    .title {
        font-size: 1.5rem;
        font-weight: 600;
    }
    .container {
        form {
            display: flex;
            flex-direction: column;
            gap: 0.7rem;
            .inputs-container {
                display: flex;
                gap: 1rem;
            }
            .sector {
                border: 1px solid rgb(217, 217, 217);
                border-radius: 5px;
                padding: 0.5rem;
                padding-top: 1rem;
                font-size: 0.8rem;
                position: relative;
                margin-top: 0.5rem;
                display: flex;
                flex-direction: column;
                gap: 0.7rem;
                .title {
                    font-size: 1rem;
                    position: absolute;
                    top: -0.8rem;
                    background-color: white;
                    color: rgba(89, 101, 121, 1) !important;
                    padding-inline: 0.3rem;
                }
            }
            span.asterik {
                color: red;
            }
            .input-container {
                display: flex;
                flex-direction: column;
                gap: 0.3rem;
                position: relative;
                &.checkbox {
                    flex-direction: row;
                }
                &.small {
                    input, select, textarea {
                        padding: 0.1rem;
                    }
                }
                input, select, textarea {
                    border: 1px solid rgba(217, 217, 217, 1);
                    padding: 0.3rem;
                    border-radius: 2px;
                    &::placeholder {
                        color: rgba(217, 217, 217, 1);
                    }
                }
                textarea {
                }
                label {
                    color: rgba(89, 101, 121, 1);
                }
                &.no-border {
                    input, select {
                        border: none;
                    }
                }
            }
            .button-container {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 1rem;
                .update-options {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 1rem;
                    .input-container {
                        flex-direction: row;
                        align-items: center;
                        &.small {
                            font-size: 0.8rem;
                        }
                        input {
                            width: fit-content;
                        }
                    }
                    
                }
                .button {
                    flex: 1;
                }
            }
        }
    }
}